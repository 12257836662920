import Bugsnag from "@bugsnag/js";
import { ShopifyWorker } from "@/workers/shopify/src/client";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { AlpineComponent } from "alpinejs";

dayjs.extend(utc);

interface SubscriptionState {
  isOpen: boolean;
  isSubscribed: boolean;
  loading: boolean;
  errorMessage: string;
  resultMessage: string;
  email: string;
}

interface SubscriptionMethods {
  showModalWithDelay: () => void;
  initialization: () => void;
  subscribeUser: () => Promise<void>;
  closeModal: () => void;
}

type State = SubscriptionState & SubscriptionMethods;

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const MESSAGES = {
  EMPTY_EMAIL: "Email cannot be empty.",
  INVALID_EMAIL: "Invalid email format.",
  VALID_EMAIL: "Email is valid.",
  SUCCESS: "Thanks for subscribing!",
  ERROR: "Oops, something went wrong. Please try again.",
} as const;

const COOKIE_NAMES = {
  SUBSCRIBED: "_dp_newsletter_subscribed",
  LAST_CLOSED: "_dp_newsletter_last_closed",
} as const;

const TIMINGS = {
  MODAL_DELAY: 4000,
  CLOSE_DELAY: 2000,
} as const;

const validateEmail = (
  email: string
): { isValid: boolean; message: string } => {
  const trimmedEmail = email.trim();
  if (!trimmedEmail) return { isValid: false, message: MESSAGES.EMPTY_EMAIL };
  return EMAIL_REGEX.test(trimmedEmail)
    ? { isValid: true, message: MESSAGES.VALID_EMAIL }
    : { isValid: false, message: MESSAGES.INVALID_EMAIL };
};

const setCookie = (name: string, value: string, expires?: number) => {
  Cookies.set(name, value, expires ? { expires } : undefined);
};

export function EmailSubscription(): AlpineComponent<State> {
  const initialState: SubscriptionState = {
    isOpen: false,
    isSubscribed: false,
    loading: false,
    errorMessage: "",
    resultMessage: "",
    email: "",
  };

  const checkSubscriptionStatus = (): boolean => {
    const subscribed = Cookies.get(COOKIE_NAMES.SUBSCRIBED);
    return subscribed === "true";
  };

  const shouldShowModal = (): boolean => {
    const lastClosed = Cookies.get(COOKIE_NAMES.LAST_CLOSED);
    if (!lastClosed) return true;

    const twoDaysAgo = dayjs().subtract(2, "day");
    return dayjs(lastClosed).isBefore(twoDaysAgo);
  };

  const handleSubscriptionResponse = (
    state: SubscriptionState,
    ok: boolean
  ) => {
    if (ok) {
      state.resultMessage = MESSAGES.SUCCESS;
      state.isSubscribed = true;
      setCookie(COOKIE_NAMES.SUBSCRIBED, "true", 365);
      state.email = "";
    } else {
      state.errorMessage = MESSAGES.ERROR;
    }
  };

  return {
    ...initialState,

    init() {
      this.$watch("isOpen", (value) => {
        if (!value && !this.isSubscribed) {
          setCookie(COOKIE_NAMES.LAST_CLOSED, dayjs().toISOString());
        }
      });

      this.initialization();
    },

    showModalWithDelay() {
      setTimeout(() => {
        this.isOpen = true;
      }, TIMINGS.MODAL_DELAY);
    },

    initialization() {
      this.isSubscribed = checkSubscriptionStatus();
      if (!this.isSubscribed && shouldShowModal()) {
        this.showModalWithDelay();
      }
    },

    async subscribeUser() {
      this.errorMessage = "";
      this.resultMessage = "";

      const { isValid, message } = validateEmail(this.email);
      if (!isValid) {
        this.errorMessage = message;
        return;
      }

      this.loading = true;
      try {
        const result = await ShopifyWorker.customers.subscribe.$post({
          json: { email: this.email.trim() },
        });
        handleSubscriptionResponse(this, result.ok);
      } catch (error: unknown) {
        this.errorMessage = MESSAGES.ERROR;
        if (error instanceof Error) {
          Bugsnag.notify(error);
        }
      } finally {
        this.loading = false;
        this.closeModal();
      }
    },

    closeModal() {
      setTimeout(() => {
        this.isOpen = false;
      }, TIMINGS.CLOSE_DELAY);
    },
  };
}
